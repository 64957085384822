/* barlow-condensed-regular - latin */
@font-face {
    font-family: 'Barlow Condensed';
    font-style: normal;
    font-weight: 400;
    src: url('barlow-condensed-v3-latin/barlow-condensed-v3-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Barlow Condensed Regular'), local('BarlowCondensed-Regular'),
    url('barlow-condensed-v3-latin/barlow-condensed-v3-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('barlow-condensed-v3-latin/barlow-condensed-v3-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('barlow-condensed-v3-latin/barlow-condensed-v3-latin-regular.woff') format('woff'), /* Modern Browsers */ url('barlow-condensed-v3-latin/barlow-condensed-v3-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('barlow-condensed-v3-latin/barlow-condensed-v3-latin-regular.svg#BarlowCondensed') format('svg'); /* Legacy iOS */
}

