@import url('~font-awesome/css/font-awesome.css');
// Bootstrap 4
@import '~@macrocom/mdbootstrap-pro/dist/css/bootstrap.css';
@import '~@macrocom/mdbootstrap-pro/dist/css/mdb.css';
@import url('fonts/fonts.css');
@import url('https://fonts.googleapis.com/css?family=Barlow:400,700&display=swap');

//colors
$Gray-100: #f5f5f5;
$Gray-500: #ccc;
$Gray-600: #999999;
$Gray-700: #666;
$Gray-900: #333;

$primary-color: #e2001a;
$secondary-color: #b7a79d;
$alternative-color: $Gray-700;
$surface: #ffffff;

h1, h2, h3, h4, h5 {
  font-family: "Barlow Condensed", sans-serif !important;
}

body {
  font-family: 'Barlow', sans-serif;
  color: $Gray-900;
  font-size: 16px;
  line-height: 1.5;
  text-align: left;

  h1 {
    font-weight: 600;
    font-size: 36px;
    color: $Gray-900;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  h2, h3 {
    font-size: 24px;
    font-weight: 500;
    color: $Gray-900;
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  h3 {
    font-size: 24px;
    color: $primary-color;
  }

  h4 {
    font-size: 18px;
  }

  h5 {
    color: $primary-color;
    font-size: 16px;
  }

  //Bootstrap Slider
  span.thumb.active {
    background-color: $primary-color !important;
  }

  input[type=range]::-webkit-slider-thumb {
    background: $primary-color !important;
  }

  input[type=range]::-moz-range-thumb {
    background: $primary-color !important;
  }

  input[type=range]::-ms-thumb {
    background: $primary-color !important;
  }

  input.select-dropdown {
    //z-index: 9999999;
    ///background-color: white !important;
  }

  .range-field input[type=range] + .thumb {
    background-color: $primary-color !important;
  }

  .form-control:focus {
    border-color: $primary-color;
    box-shadow: none;

  }

  a {
    color: $primary-color !important;
  }

  .was-validated .form-control:valid, .md-form input[type=text], .md-form input[type=range] .md-form, .md-form label, .repaymentTermLabel, .repaymentTerm, was-validated .form-control:valid:focus, .was-validated .form-control:valid:focus {
    color: $Gray-600 !important;
    border-color: $Gray-500 !important;
  }

  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: $Gray-600;
  }

  .btn-primary {
    background-color: $primary-color !important;
    border-color: $primary-color;
    color: white !important;
    font-family: 'Barlow', sans-serif;
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    border: solid 1px $primary-color;

    &:hover {
      box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .18), 0 4px 15px 0 rgba(0, 0, 0, .15);
      color: $primary-color;
      border-color: $primary-color;
    }
  }

  .select-wrapper + label {
    top: .20rem;
    color: $Gray-600;
    position: absolute;
    font-size: .8rem;
    display: inline-block;
    margin-bottom: .5rem;
  }

  #overlay {
    display: none;
  }

  .site {
    padding-top: 77px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    .fkr-form {
      margin-top: 10px;
      margin-bottom: 10px;

      .fkr-button {
        margin: 20px 0 20px 0;

      }

      .div-comparisonOffer {
        display: none;
      }
    }

    .logo-select, .template-select {
      border: 0;
      padding-left: 0;
    }

    .variante {
      color: $secondary-color;
    }

    .div-wunschrate {
      display: none;
    }

    .form-check {
      padding-left: 0 !important;

      input[type=checkbox]:checked + label:before {
        border-right: 2px solid $primary-color;
        border-bottom: 2px solid $primary-color;
      }

      input[type=checkbox]:not(:checked) + label {
        color: $Gray-600;
        padding-left: 1.6rem;
      }
    }

    //Header
    header.fixed {
      position: fixed;
      width: 100%;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1000;
    }

    header {
      max-width: 1920px;
      margin: auto;
      background: white;

      .no-gutters {
        padding-top: .5rem;
        margin-left: 0;
        margin-right: 0;
        //padding-left: 1rem;
        padding-bottom: 1rem;

        img.logo {
          width: 160px
        }
      }
    }

    .container-fluid, .container {
      -ms-overflow-style: none; // IE 10+
      scrollbar-width: none; // Firefox

      .card-header {
        background-color: $Gray-900;
        color: white;
        font-size: 24px;
      }

      .md-form input[type=range] {
        &:focus {
          border-color: transparent;
          border-bottom: 0;
        }
      }

      .md-form input {
        &:focus {
          box-shadow: 0 0 0 0 $Gray-700;
          border-bottom: 1px solid $Gray-700;
          box-sizing: border-box;

        }
      }

      .dropdown-content li > span {
        color: $Gray-900;
        font-size: 1rem;
        padding: .53rem 8px;
      }
    }

    .container-fluid.pagecontent {
      margin-top: 50px;
      margin-bottom: 50px;
      background: white;
      position: relative;
      width: 100%;
    }

    .infobar {
      padding-top: .5rem !important;
      padding-bottom: .5rem !important;
      background-color: $Gray-900;
      text-align: center;
    }

    .login {
      margin-top: 20px;
      margin-bottom: 20px;
      padding-top: 30px;
      padding-bottom: 30px;

      .login-form {
        .header {
          background-color: $Gray-900;

          h2 {
            font-size: 30px !important;
            color: white;
          }
        }
      }
    }

    .pdf-download {
      margin-top: 20px;
      margin-bottom: 20px;
      display: block;
      text-align: right;
      color: $primary-color;
      cursor: pointer;
    }
  }

  #overlay {
    overflow: hidden;
    background: #ffffff;
    color: $primary-color;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 5000;
    top: 0;
    left: 0;
    float: left;
    text-align: center;
    padding-top: 25%;
    opacity: .80;

    .spinner {
      margin: 0 auto;
      height: 64px;
      width: 64px;
      animation: rotate 0.8s infinite linear;
      border: 5px solid $primary-color;
      border-right-color: transparent;
      border-radius: 50%;
      @keyframes rotate {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }

  .functionGuarantee, .herstellerlogo {
    display: none
  }

  .functionGuarantee {
    margin-bottom: 12px;
    input {
      margin-top: 20px !important;
      margin-bottom: 20px !important;
    }
    .select-wrapper {
      margin-top: 0px;
      margin-bottom: 34px;
    }
  }

  .merchant-contact {
    display: none;
    margin-bottom: 10px;
    margin-top: 10px;
    background-color: $Gray-100;
    padding: 15px 15px;
  }

  span.character-counter:nth-of-type(2) {
    display: none;
  }
}

#macrocom_fkr_bundlefkr_form_repaymentTerm {
  border: none !important;
}

#tb-financing-prev {
  td, th {
    font-weight: 400;
    font-size: 16px;
  }
}